.carosel-wrapper {
  padding: 80px 200px 20px 200px;
}
.rec.rec-arrow {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  min-width: 30px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: black;
}

.rec.rec-arrow:hover {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  min-width: 30px;
  background: #f1f1f1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: black !important;
}

.rec.rec-arrow:focus {
  outline: none;
  background: #f1f1f1 !important;
  color: black !important;
  box-shadow: inset 0 0 1px 1px lightgrey;
}

.rec.rec-dot {
  display: none;
}

@media (max-width: 900px) {
  .carosel-wrapper {
    padding: 0px;
  }
}
