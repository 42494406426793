.dropzone-app {
  font-family: sans-serif;
}

.dropzone-app-ul {
  margin: 0;
}

.dropzone-app-ul-span {
  font-size: 2rem;
  color: #777;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 2px dashed #3aa7e4;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}
