.modal-body {
  padding: 0px;
}

.modal-content {
  border: 0;
  border-radius: 0;
}

.m-body {
  padding: 40px 40px 0px 40px;
}

.form-check-input {
  margin-top: 4px;
}

.log-checkbox {
  display: flex;
  justify-content: space-between;
}
