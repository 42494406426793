.modal-body {
  padding: 0px;
}

.custom-expert-modal {
  width: 50%;
}

.custom-expert-modal > .modal-content {
  border: 0;
  background: #f7f7f7;
  overflow: hidden;
  border-radius: 30px !important;
}

.custom-expert-modal > .modal-content > .modal-header {
  border-bottom: 0px;
  background: #fff !important;
}

.custom-expert-modal .m-body {
  padding: 20px 40px 20px 40px;
  background: #f7f7f7;
}
.mod-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.m-body {
  padding: 40px 40px 0px 40px;
}

.form-check-input {
  margin-top: 4px;
}

.log-checkbox {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .custom-expert-modal {
    width: 100%;
  }
}
