.logo1 {
  width: 100px;
  margin-right: 20px;
}
.headerLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // flex-grow: 70;
  width: 60%;
}
.headerRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // flex-grow: 30;
}
p {
  color: #4d4c4c;
  font-size: 16px;
  line-height: 23px;
}
.auth-bg {
  background: #e1e1e1;
  height: 100vh;
}
.auth {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.au-section {
  background: $white;
  padding: 40px;
  width: 500px;
}
// div:last-child hr {
//   display: none;
// }

.subscribe {
  color: $primary-color;
}
.mob-v {
  margin: 0 auto;
  width: 700px;
  margin-top: 30px;
}
.title {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0d1136;
}

.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #797b8f;
}

.custom-input {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $black;
  border-color: $input-border;
  background: $input-bg;
  height: $h50;
}

.custom-select {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $input-text;
  border-color: $input-border;
  background: $input-bg;
  height: $h50;
}

.custom-input-search {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $input-text;
  border-color: $input-border;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background: $input-bg;
  height: $h50;
}

.custom-btn-search {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background: $primary-color;
  border-color: $primary-color;
  color: $white;
  // height: 40px;
  width: 220px;
}

.custom-btn-reset {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: $primary-dark-color;
  margin-left: 20px;
  // height: 40px;
  width: 220px;
}

.custom-btn-search i {
  color: white;
  font-size: 17px;
}

.custom-input-filter {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: $primary-dark-color;
  border-color: $input-border;
  background: $white;
  height: $h50;
  width: 100%;
}

.custom-btn {
  background: $primary-color;
  border-color: $primary-color;
  color: $white;
  height: $h50;
}

.custom-adm-btn {
  background: $primary-color;
  align-self: flex-end;
  margin-top: 30px;
  width: 200px;
  border-color: $primary-color;
  color: $white;
  height: 40px;
}

.file-details {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $input-border;
  padding: 10px;
  border-radius: 5px;
}

.file-details-upload {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $input-border;
  padding: 10px;
  border-radius: 5px;
}

.fileBtn {
  border-width: 2px;
  background: $primary-color;
  border-top-color: $primary-color;
  border-bottom-color: $primary-color;
  border-right-color: $primary-color;
  border-left-color: $primary-color;
  color: $white;
  font-weight: 600;
  font-size: 14px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.main-banner {
  height: 1130px;
  padding: 80px 20px 20px 30px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
}

.main-banner-section {
  padding: 80px 20px 20px 50px;
}

.interview {
  margin-top: 60px;
}

.int-h3 {
  // font-size: 80px;
  font-weight: bold !important;
  text-align: center;
}

.int-p {
  font-size: 25px !important;
  font-weight: 200px;
  line-height: 40px !important;
  text-align: center;
}

.popular-details h3 {
  font-size: 36px;
  font-weight: bold;
}

.popular-details p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

.e-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.e-p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.e-p span {
  font-size: 24px;
  font-weight: bold;
  color: $primary-color;
  margin-left: 20px;
}

.e-icon {
  font-size: 80px;
  color: $primary-color;
}

.banner-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.banner-details h1 {
  font-size: 70px;
}

.banner-details p {
  font-size: 18px;
  color: $primary-dark-color !important;
  padding-right: 20px;
  line-height: 40px;
  font-weight: 300;
}
.mainHeader {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  padding: 15px;
}
.topHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topHeader h3 {
  margin-top: 5px;
  text-align: center;
  // flex: 1 1 0px;
}

.topHeader-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topHeader-b h3 {
  margin-top: 5px;
  text-align: center;
  flex: 1 1 0px;
}

.custom-icon {
  color: $primary-color;
  font-size: 40px !important;
  font-weight: bold;
}

.logo {
  cursor: pointer;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  color: $primary-color;
}
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  height: 90vh;
}
// sidebar and content
.sidebar {
  width: 20%;
  background: $white;
  height: 100vh;
}

.sidebar a {
  // display: block;
  color: $primary-dark-color;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  padding: 16px;
  // align-items: center;
}

.sidebar a.active {
  background-color: #f7f7f7;
  color: $primary-color;
  border-radius: 30px 0px 0px 30px;
}

.innderSidebar {
  padding-top: 30px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
}

.sidebar a:hover:not(.active) {
  color: $primary-color;
}

.sidebarIcon {
  font-size: 18px !important;
  margin-top: -2px;
  margin-right: 5px;
}

.sidebarText {
  font-size: 17px;
  margin-top: 2px;
}

.content {
  background: #f7f7f7;
  width: 80%;
  padding: 50px;
  height: 100vh;
}
.dashboardItems {
  border-radius: 10px;
  background: $white;
  padding: 20px;
  margin-bottom: 20px;
  min-height: 230px;
}
.items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.itemIcon {
  background: #d6e4dd;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemCounter {
  padding: 25px;
}

.profileSection {
  display: flex;
  justify-content: center;

  height: 100%;
  padding-top: 40px;
}
.profile {
  background: #ffffff;
  padding: 40px;
  width: 50%;
}
.profileImg {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileBody {
  margin-top: 30px;
}

.actionBody {
  position: fixed;
  bottom: 0;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  background: $white;
}
.main-area {
  margin-bottom: 20px;
  border-radius: 15px;
  min-height: 370px;
  border: 1px solid $input-border;
  overflow: hidden;
}
.expertBannerPopular {
  background-size: 100% 100% !important;
  height: 100px;
  background: url('../images/expertbanner.png');
}
.expertBanner {
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  height: 200px;
  background: url('../images/expertbanner.png');
}
.expertSection {
  margin-top: 30px;
}
.loader-p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.prof {
  font-size: 11px !important;
  line-height: 15px !important;
  margin-bottom: 5px;
}
.al-list {
  display: flex;
  justify-content: center;
  align-content: center;
}

.al-list small {
  font-size: 9px;
  padding: 5px;
}

.details-list {
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
  display: block;
  margin-bottom: 20px;
}

.employe-icon:hover {
  text-decoration: none;
}

.add-user-request {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  padding: 5px;
  border-radius: 15px;
  font-size: 13px;
  color: $primary-color;
  width: 100px;
  margin-bottom: 20px;
}
.pad15 {
  padding: 15px;
}
.al-list small > i {
  color: $primary-color;
  font-size: 8px;
}
.profileExpert {
  overflow: hidden;
  background: #ffffff;
  width: 80%;
  border-radius: 20px;
  margin-bottom: 80px;
}
.profileExpertImg {
  width: 150px;
  height: 150px;
  background: #f7f7f7;
  border-radius: 75px;
  margin-top: -75px;
}

.profileExpertBody {
  padding: 0px 40px 40px 40px;
  margin-top: 30px;
}
.profileExpertBody h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 33px;
  color: #0d1136;
}
.profileExpertBody p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;

  color: #77798c;
}

.actionBodyExpert {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background: $white;
}
.expertImg {
  border-radius: 10px;
  width: 150px;
  // height: 150px;
}

.roundImg {
  border-radius: 75px;
  background: #f1f1f1;
  width: 150px;
  height: 150px;
}
.exployeDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.adminNotes {
  display: -webkit-flex;
  -webkit-flex-direction: column;
  display: flex;
  flex-direction: column;
}

.exployeSkills {
  display: flex;
  flex-direction: column;
}
.skill {
  margin-top: 15px;
}
.skill span {
  color: #3aa7e4 !important;
  border: 1px solid #3aa7e4;
  border-radius: 30px;
  font-size: 13px;
  /* height: 40px; */
  padding: 5px;
  /* margin-top: 20px; */
  min-width: 70px;
  display: inline-block;
}

.empLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.empRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.t-emp {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  font-size: 15px !important;
  font-weight: bold !important;
  line-height: 23px !important;
  color: #0d1136 !important;
  margin-bottom: 5px;
}

.t-emp small {
  font-weight: normal;
  font-size: 13px !important;
  // margin-left: 40px;
}

.p-exp {
  font-size: 15px !important;
  line-height: 23px !important;
  color: #0d1136 !important;
  margin-bottom: 5px;
}

.p-det {
  font-size: 13px !important;
  line-height: 21px !important;
  margin-bottom: 5px;
}

.btnCv {
  border-width: 2px;
  background: transparent;
  border-top-color: $primary-color;
  border-bottom-color: $primary-color;
  border-right-color: $primary-color;
  border-left-color: $primary-color;
  color: $primary-color;
  font-weight: bold;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.skillDiv {
  border-width: 2px;
  background: transparent;
  border-top-color: $primary-color;
  border-bottom-color: $primary-color;
  border-right-color: $primary-color;
  border-left-color: $primary-color;
  color: $primary-color;
  font-weight: 400;
  height: 30px;
  font-size: 14px;
  border-radius: 20px;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.skillDivActive {
  border-width: 2px;
  background: transparent;
  border-top-color: green;
  border-bottom-color: green;
  border-right-color: green;
  border-left-color: green;
  color: green;
  font-weight: 400;
  height: 30px;
  font-size: 14px;
  border-radius: 20px;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.skill-icon {
  color: $white;
  margin-right: 5;
  width: 20px;
  height: 20px;
  background: $primary-color;
  margin-left: -8px;
  margin-right: 5px;
  border-radius: 50px;
  padding: 4px;
  font-size: 12px;
}

.skill-icon-active {
  color: $white;
  margin-right: 5;
  width: 20px;
  height: 20px;
  background: green;
  margin-left: -8px;
  margin-right: 5px;
  border-radius: 50px;
  padding: 4px;
  font-size: 12px;
}

.active-rec {
  color: $primary-color;
}

.upload-file {
  color: $primary-color;
}

.cRow {
  margin: 0;
}

.table-bordered td {
  border: none;
  font-size: 12px;
}

.table-active-col {
  color: $primary-color;
  font-weight: bold;
}

.user-filter {
  background: white;
  padding: 20px;
  margin-bottom: 20px;
}

// custom select box
/* remove the original arrow */
select.custom-input-filter {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /* no standardized syntax available, no ie-friendly solution available */
}

.custom-input-filter + i.fa {
  float: right;
  margin-top: -39px;
  margin-right: 5px;
  pointer-events: none;
  background-color: #fff;
  padding-right: 5px;
  font-size: 26px;
  color: $paragraph;
}

.filter-actions {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #757575;
  border: 1px solid;
  border-color: $input-border;
  background: $white;
  height: $h50;
  width: 100%;
}

.filter-action-items {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.form-check-input {
  position: absolute;
  margin-top: 2px;
  // margin-left: 0;
}

.filter-select {
  padding: 0px 20px 10px 20px;
}

.profile-btn {
  background: transparent;
  border: none;
  font-weight: 500;
}

.user-logo {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-top: -8px;
}

.close-drawer {
  background: #f7f7f7;
  color: $black;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-content {
  padding: 20px 0px 20px 30px;
  background: #eeeeee;
}

.p-drawer {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #4d4c4c;
}

.p-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #77798c;
  margin-bottom: 5px;
}

.drawer-content h5 {
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}

.profile-details {
  background: white !important;
  padding: 30px;
  border-radius: 5px;
}

.drawer-img {
  width: 116px;
  height: 116px;
  border-radius: 58px;
  overflow: hidden;
}

.request-img {
  width: 130px;
  height: 130px;
  border-radius: 65px;
  overflow: hidden;
  margin-top: -30px;
}

.employe-icon {
  margin-left: 10px;
  background: #3aa7e4;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-footer {
  position: fixed;
  bottom: 0;
  width: 950px;
  padding: 10px;
  text-align: center;
  background: $white;
}

.footer {
  padding: 50px 50px 10px 50px;
  background: #0d1136;
  color: $white;
}

.footer h4 {
  font-weight: bold;
  font-size: 35px;
}

.footer p {
  color: gray;
}

.footer-icon {
  color: $primary-color;
  font-size: 25px;
  margin-right: 10px;
}

.modal-footer-body {
  background: $input-border;
  padding: 5px;
}

.edit-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: $primary-color;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
}
.dropzone-parent {
  position: relative;
}
.selection {
  position: absolute;
  top: 15px;
  left: 140px;
}

.selection-big {
  position: absolute;
  bottom: 15px;
  left: 210px;
}

.edit-icon i {
  color: $primary-color !important;
  font-size: 20px;
}
.img-profile-p {
  position: relative;
}

.img-profile-p > .edit-icon {
  position: absolute;
  background: white;
  bottom: -50px;
  right: 40px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
}
.img-download {
  position: relative;
}

.img-download > div {
  position: absolute;
  background: white;
  bottom: -5px;
  right: -2px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
}

.cal-p {
  margin-bottom: 15px;
  border-radius: 5px;
  background: #f1f1f1;
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.cal-p-l {
  flex-grow: 99;
  margin-right: 2%;
}

.cal-p-r {
  flex-grow: 1;
}

.cal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  height: 30px;
}

.cal span {
  font-size: 14px;
}

.time-p {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 0px;
  align-items: center;
  justify-content: 'space-between';
  margin-top: 10px;
  margin-bottom: 10px;
}

.time-p-l {
  flex-grow: 47;
  margin-right: 10px;
  margin-top: -10px;
}
.time-p-m {
  flex-grow: 47;
  margin-right: 10px;
  margin-top: -10px;
}

.time-p-r {
  margin-top: -10px;
  flex-grow: 6;
}

.custom-tb {
  height: 400px;
  overflow-y: scroll;
}

.custom-tb::-webkit-scrollbar {
  display: none;
}
.custom-tb {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.timings-parent {
  height: 300px;
  overflow-y: scroll;
}

.timings-parent::-webkit-scrollbar {
  display: none;
}
.timings-parent {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.timings-t {
  display: flex;
  justify-content: space-between;
  padding: 13px 20px 10px 20px;
  border-radius: 5px;
  color: $black;
}

.timings {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  border: 1px solid gray;
  border-radius: 5px;
  color: $paragraph;
  margin-bottom: 20px;
  min-width: 95%;
}

.timer-p {
  position: absolute;
  z-index: 1;
  width: 250px;
  height: 200px;
  overflow-y: scroll;
  margin-top: -20px;
  background-color: white;
}
.timer {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
}

.timer:hover {
  background: #f1f1f1;
}

.timerActive {
  background: #e1e1e1;
}

.timer span {
  display: inline-block;
  margin-left: 20px;
}
.menu-p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.user-details {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  background: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
}

.client-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  padding-bottom: 100px;
}

.client-h1 {
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
}

.client-p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  color: $paragraph;
  text-transform: uppercase;
}

.input-search {
  display: flex;
  flex-direction: row;
}

.search-parent {
  margin-top: 20px;
  width: 700px;
}

.close-icon {
  position: absolute;
  right: 20px;
  border: 2px solid;
  padding-left: 5px;
  border-radius: 15px;
  color: $paragraph;
  width: 25px;
  height: 25px;
  padding-top: 2px;
  float: right;
  margin-bottom: 10px;
}

.test-p {
  border: 1px solid $input-border;
  margin: 20px;
  padding: 20px;
  background: #f9f9f9;
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
}

.test-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  border-radius: 20px;
  color: $primary-color;
  width: 40px;
  height: 40px;
  border: 2px solid $primary-color;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.input-row > input {
  border: none;
}

.input-row > input:focus {
  box-shadow: none;
}

.input-row > textarea {
  border: none;
  color: black;
  overflow: hidden;
}

.input-row > textarea:focus {
  box-shadow: none;
}

.input-row i {
  font-size: 20px;
}
.error {
  font-size: 12px;
  margin-top: -15px;
  display: block;
}
.react-datepicker__triangle {
  display: none !important;
}
.react-datepicker__input-container {
  display: none !important;
}
.time-p-l > .timings-t {
  background: rgb(241, 241, 241);
  height: 50px;
}
.time-p-l > div > div > input {
  padding: 16px 0 9px 9px;
  background: rgb(241, 241, 241);
}

.time-p-m > .timings-t {
  background: rgb(241, 241, 241);
  height: 50px;
}
.time-p-m > div > div > input {
  padding: 16px 0 9px 9px;
  background: rgb(241, 241, 241);
}
@media screen and (max-width: 1024px) {
  .main-banner {
    height: 1400px;
  }
  .int-h3 {
    font-size: 30px;
  }
}

// responsiveness
@media (max-width: 768px) {
  .headerLeft{
    width: 70%;
  }
  .banner-h1 {
    font-size: 30px;
  }
  .request-img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    overflow: hidden;
    margin-right: 30px;
    margin-top: 10px;
  }
  .mob-v {
    margin: 0 auto;
    width: 100%;
    margin-top: 30px;
  }
  .profile {
    width: 100%;
    padding: 20px;
  }

  .expertImg {
    width: 100px;
  }
  .roundImg {
    width: 150px;
    background: #f1f1f1;
  }
  .sidebar {
    width: 10%;
  }
  .content {
    width: 90%;
  }
  .e-icon {
    font-size: 25px;
  }
  .close-icon {
    position: absolute;
    right: 20px;
  }
  .e-p span {
    font-size: 18px;
    margin-left: 8px;
  }

  .profileExpert {
    width: 90%;
  }
  .au-section {
    background: $white;
    padding: 30px;
    width: 350px;
  }
  .innderSidebar {
    padding-top: 10px;
    padding-left: 10px;
  }

  .sidebarText {
    display: none;
  }
  .filter-select {
    padding: 0px;
  }
  .exployeDetails {
    display: flex;
    flex-direction: row;
  }

  .btnCv {
    width: 150px;
    font-size: 12px;
  }

  .drawer-footer {
    position: fixed;
    bottom: 0;
    width: 300px;
    padding: 10px;
    background: $white;
  }
  .banner-img {
    display: none;
  }
  .banner-details h1 {
    font-size: 30px;
  }
  .banner-details p {
    font-size: 20px;
  }

  .main-banner-section {
    padding: 20px 20px 20px 20px;
  }
  .popular-details h3 {
    font-size: 30px;
    font-weight: bold;
  }
  .int-h3 {
    font-size: 30px !important;
    font-weight: 600px;
    text-align: center;
  }
  .main-banner {
    height: 1050px;
    padding: 20px 20px 20px 20px;
  }
  .footer {
    padding: 50px 50px 30px 50px;
  }
  .client-h1 {
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .client-p {
    font-size: 15px;
  }
  .search-parent {
    margin-top: 20px;
    width: auto;
  }

  .empLeft h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 700px) {
  .sidebar {
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  .content {
    margin-left: 0;
    padding: 20px;
  }

  .table-bordered thead td,
  .table-bordered thead th {
    font-size: 8px;
    padding: 0.25rem;
  }

  .table {
    font-size: 12px;
    padding: 0.25rem;
  }
  .empCol {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 415px) {
  .custom-btn-search {
    width: 60px;
    font-size: 12px;
  }
  .custom-btn-search i {
    display: none;
  }
  .custom-btn-reset {
    width: 60px;
    font-size: 12px;
  }
  .sidebar {
    width: 20%;
  }
  .content {
    width: 80%;
  }
  .main-banner {
    height: 1300px;
  }
  .client-banner {
    padding-top: 100px;
  }

  .headerLeft {
    width: 100%;
    flex-direction: column;
  }
  .topHeader {
    display: flex;
    flex-direction: column;
  }

  .topHeader h3 {
    margin-top: 9px;
  }
  .logo {
    font-size: 16px;
  }
  .sidebar a {
    text-align: center;
    float: none;
  }
  .client-h1 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .client-p {
    font-size: 10px;
  }

  .client-banner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 380px) {
  .main-banner {
    height: 1400px;
  }
}

@media screen and (max-width: 330px) {
  .main-banner {
    height: 1450px;
  }
}
