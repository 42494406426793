$primary-color: #3aa7e4;
$primary-dark-color: #0d1136;

$primary-bg: #ffffff;

$white: #ffffff;
$black: #000000;

$success: #3f9c35;
$error: #cc0000;
$warning: #ec7a08;
$info: #00b9e4;
$active: #0088ce;

$paragraph: #797b8f;
$input-text: #797b8f;
$input-border: #f1f1f1;
$input-bg: #f7f7f7;
